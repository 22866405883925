<template>
  <router-view />
</template>
<style>
.formkit-wrapper{
  max-width: 100% !important;
}
.show{
  display: block;
}
.hide{
  display: none;
}
.sec-title{
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #000000BB;
}
.formkit-label{
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; 
  font-weight: 500 !important;
  font-size: 1em !important;
  letter-spacing: -.04em !important;
  margin-bottom: -4px !important;
  color:#000000AA
}
.formkit-input{
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; 
  font-weight: normal !important;
  padding:.5em .6em !important;
  font-size: .99em !important;
  
}
.formkit-message{
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; 
  font-weight: normal !important;
  font-size: 1em !important;
  
}
.formkit-legend{
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; 
  font-weight: normal !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
}
.font-card-button{
  font-weight: 400 !important;
  font-size: medium;
}
button.formkit-input{
   font-size: 1.1em !important;
   padding: .4em .8em .4em .8em !important;
   
}
.vs__dropdown-toggle{
  border: solid 1px #a8a6ae !important;
  padding-top:4px !important;
  padding-bottom:6px !important;
}
.vs__search{
  padding: 0 .5em !important;
  font-size: 1.1em !important;
  letter-spacing: -.5px ;
  margin: 0 !important;
}
.vs__spinner{
    background-color:#FF000033 !important;
}
.view-info-card p, .view-info-card b{
  font-size:14px;
  letter-spacing: -0.5px;
  padding-inline-start: .9em;
  padding-inline-end: .7em;
}
.view-info-card b{
  color:#0000009F;
}
.view-info-card hr{
  margin-inline-start: .9em !important;
  margin-inline-end: .9em !important;
}
.vgt-table{
  letter-spacing: -1px;
} 

.modal-header{
  background-color: #f7f7f7;
  height: 50px;
}

.modal-title{
  letter-spacing: -1px;
}

.page-sub-head{
   background-color: #eaeaea;
   padding: .4em .3em .3em .9em;
   color: green;
   font-weight: normal ;
   font-size: 1.1em; 
}

.page-sub-head-2{
   padding: .4em .3em .3em .7em;
   color: gray;
   font-weight: normal ;
}

.textvselect{
  font-size: 16px;
}

.simple-box {
    border: solid 1px #e9e9e9;
    padding-bottom: 6px;
    border-radius:7px;
}

.title-data{
    max-height: 25px;
    padding: .2em;
}

.flag-title{
  background: #FFF;
  color: #8B4000;
  border-radius:3px;
}

.vgt-table td .btn{
    padding: 1px 10px !important;
    font-size: small;
}
.vgt-table td {
   padding: 7px 7px !important;
   font-size: .90em;
   line-height: .90em;
   vertical-align: middle !important;
}

.vgt-table th {
   font-size: .95em;
   line-height: .95em;
   vertical-align: middle !important;
}

.vgt-wrap__footer {
  padding: .28em 2em !important;
}

.vgt-wrap__footer *{
 font-size: .95em !important;
 
}
.btnsml{
  font-size: small !important;
  padding: 1px 8px !important;
  margin: auto !important;
  letter-spacing: -.5px;
}
.btnshrink{
  padding: 0px 10px !important;
  margin-top:.28em !important;
}

[data-type="submit"] .formkit-input {
  padding:3px 10px !important;
  font-size: 15px !important;
}

.dropdown-item{
    font-size: .8em;
}

.bg-burn-orange{
  background-color: #CC5500 !important;
}

.bg-dark-orange{
  background-color: #8B4000 !important;
}

.bg-maroon{
  background-color:#800000 !important;
}

.bg-lazuli-blue{
  background-color:#26619C !important;
}

.bg-royal-blue{
  background-color:#002366 !important;
}

.bg-dark-yellow{
  background-color:#8B8000 !important;
}
.bg-steelblue{
  background-color:#4682B4 !important;
}
.bg-olive{
  background-color:#808000 !important;
}
.bg-brown{
  background-color:#A52A2A !important;
}
.bg-indigo{
  background-color:#4B0082 !important;
}

.c-royal-blue{
  color:#002366 !important;
}

.c-maroon{
  color:#800000 !important;
}

.c-d-orange{
  color:#8B4000 !important;
}

.c-indigo{
  color:#4B0082 !important;
}

.stitle{
    font-weight: 500;
    color:green;
}

/* For transition */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.paid-for{
    color:green !important;
    letter-spacing: -.4px !important;
    font-size: small !important;
    font-weight:500;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* For transition */
.v-sel-bold{
  font-weight: 450;
}
.btn-tiny{
  max-height: 15px;
  font-size: 11px !important;
  padding: 0px 7px 17px 7px !important;
  border-radius:3px !important;
  text-align:center ;
}

.customtable-view
{
  margin:-17px -15px -22px -21px;
}
.customtable-view .customtable-head
{
  padding: 10px 24px;
  border-radius: 0px !IMPORTANT;
    background: #fff;
    border-bottom: 1px solid #ececec !important;
}
.customtable-view .customtable-head .sec-title
{
  font-size: 18px !important;
    letter-spacing: 0px;
    font-weight: 700;
    line-height: 20px;
}
.customtable-view .customtable-head .sec-title b
{
  font-size: 13px;
    font-weight: 400;
}
.customtable-view .customtable-head .sec-title span
{
  font-size: 13px;
}
.customtable-view .customtable-head .custom-table-actions
{
  padding-top:4px;
}
.customtable-view .customtable-head .custom-table-actions button
{
  margin-left: 10px;
}
.customtable-view .vgt-table th.line-numbers, .customtable-view .vgt-table th.vgt-checkbox-col
{
  background-image: none !important;
  background: #f6f9ff;
}
.customtable-view .vgt-table thead th
{
  background: #f6f9ff;
    font-size: 13px;
    color: #292550;
    font-weight: 400;
    white-space: nowrap;
}
.customtable-view .vgt-table th.filter-th
{
  padding:0px;
}
.customtable-view .vgt-table th.filter-th input, .customtable-view .vgt-table th.filter-th select
{
  border-radius: 0px;
  border-color: transparent;
}
.customtable-view .vgt-table th.filter-th input:focus, .customtable-view .vgt-table th.filter-th select:focus, .customtable-view .vgt-table th.filter-th input:hover, .customtable-view .vgt-table th.filter-th select:hover
{
  border-color:#7b7bf2;
}
.customtable-view table.vgt-table td
{
  color: #48464f;
    font-size: 0.8rem;
    line-height: 22px;
}
.customtable-view .vgt-table.bordered td, .customtable-view .vgt-table.bordered th {
    border: 1px solid #eaebef;
}
.customtable-view .vgt-table.striped tbody tr:nth-of-type(odd) {
    background-color: rgb(245 246 249 / 35%);
}
.customtable-view .vgt-responsive
{
  height: calc(100vh - 151px);
}
.customtable-view .vgt-wrap__footer
{
  position: fixed;
    bottom: 0px;
    width: calc(100% - 260px);
    padding-left: 18px !important;
    padding-right: 0px !important;
}
.customtable-view table.vgt-table
{
  margin-top: -1px;
  margin-bottom: 25px;
}
.customtable-view .nodata-wrapper 
{
height: calc(100vh - 232px);
}
.customtable-view .nodata-wrapper img
{
  width: 200px;
    margin-top: 5%;
    margin-bottom: 21px;
}
.customtable-view .nodata-wrapper b
{
  width: 100%;
    display: block;
    font-size: 19px;
    color: #474747;
}
.customtable-view .toast
{
  position: fixed;
    bottom: 10px;
    z-index: 999;
    right: 11px;
    background: #fff;
    padding: 10px;
}
.customtable-view .vgt-global-search
{
  padding: 0px;
    border-top: 0px;
    background: #fff;
    display: block;
}
.customtable-view .vgt-global-search__input
{
  padding-left: 0px;
  width:100%;
}
.customtable-view .vgt-global-search__input .vgt-input:hover, .customtable-view .vgt-global-search__input .vgt-input:focus
{
  border-color:#7b7bf2;
}
.customtable-view .vgt-global-search__input .vgt-input
{
  border-radius: 0px;
    text-indent: 22px;
    border-color: transparent;
    border-bottom: 1px solid #dfe2ee;
    padding: 15px;
}
.customtable-view .vgt-global-search__input .input__icon .magnifying-glass
{
  zoom: .80;
    margin-left: 11px;
    margin-top: 6px;
}
.customtable-view.singlesearch .vgt-responsive
{
  height: calc(100vh - 176px) !important;
}

.no-margin
{
    margin:0px !important;
}
.box {
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
}

.box .head
{
    float: left;
    width: 100%;
}
.box .head span
{
    padding: 8px;
    border-radius:5px;
    font-size: 24px;
    float: left;
}
.box .head h6
{
    float: left;
    margin: 0px 5px 0px 15px;
    font-weight: 500 !important;
    width: 80%;
    padding: 0px;
    font-size: 14px;
}
.box .description
{
    font-size: 12px;
    float: left;
    width: 80%;
    margin-top: 3px;
    font-weight: 400;
    color: #7d7d7d;
    margin-left: 15px;
    margin-bottom: 8px;
}
.padding-0{
    padding-right:0  !important;
    padding-left:0  !important;
    background: #fff  !important;
    border-right: 1px solid #e7e8ec;
    border-bottom: 1px solid #e7e8ec;
}
.padding-0:hover
{
    background: #fbfbff !important;
}
.padding-0:hover h6
{
    color: #4b3ebb;
}
.block-menu
{
    background: none !important;
    border: 0px !important;
    padding: 7px 22px;
    border-radius: 0px;
    margin: 0px !important;
}
.actions a
{
    margin-left: 10px;
    padding: 2px 9px 1px 9px ;
}

.actions a i
{

}
.actions
{
    position: absolute;
    right: 23px;
    margin-top: 5px;
    display: inline;
   
}
.block-menu:hover .actions
{
    display: inline !important;
}
.group-page-title
{
    padding: 14px 23px;
    background: #f8f7ff;
    border-bottom: 1px solid #eaeaea;
}
.group-page-title h4
{
    font-size: 14px;
    margin: 0px;
    color: #1b1d79;
}

[data-type="submit"] .custom-btn-pad{
  padding:6px 15px !important;
  font-size: 15px !important;
}
.formkit-step-actions{
   width:50%;
}
.custom-chk [data-type="checkbox"] [data-disabled]
{
    opacity:80% !important;
}
.vselect-labl{
  font-weight: 500;
  font-size:13px;
  color:#000000AA;
}
.required::after {
    content: " *";
    color: red;
}
</style>
