
function currentUser() {
    const cuser = JSON.parse(localStorage.getItem('user'));
    return cuser
}

function isHyzAdm(){
    let isHyzadm = false
    const cuser = JSON.parse(localStorage.getItem('user'));
    if(cuser.is_hyz_admin == 1 || cuser.is_hyz_sup_admin == 1){
        isHyzadm = true
    }
    return isHyzadm
}
function isMahalAdm(){
    let isMahadm = false
    const cuser = JSON.parse(localStorage.getItem('user'));
    if(cuser.is_mahal_admin == 1 || cuser.is_mahal_sup_admin == 1){
        isMahadm = true
    }
    return isMahadm
}

export const uzr = {
    currentUser,isHyzAdm,isMahalAdm
};
