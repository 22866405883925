import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'
import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/addons/css/multistep'
import VueDatetimeJs from 'vue3-datetime-js';
// import the styles 
// import { makeServer } from "./server"
// if (process.env.NODE_ENV === "development") {
//     makeServer()
// }

createApp(App)
    .use(store)
    .use(VueGoodTablePlugin)
    .use(router)
    .use(plugin, defaultConfig({
        plugins: [createMultiStepPlugin()],
      }))
      .component('date-picker', VueDatetimeJs)
    .mount('#app')