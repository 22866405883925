import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';

let loguser = uzr.currentUser()
var perm = {}

if (loguser) {
    perm = loguser.perm
}

const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/DashboardLayout.vue'),
        meta: { requiresAuth: true, },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashBoard.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/userprofile',
            name: 'user-profile',
            component: () => import('@/views/UserProfile.vue'),
            meta: { has_perm: true, },
        },

        {
            path: '/members',
            name: 'members',
            component: () => import('@/views/members/membersList.vue'),
            meta: { has_perm: perm.c_v_member, },
        },

       
        
        // {
        //     path: '/members/add',
        //     name: 'new-members',
        //     component: () => import('@/views/members/membersAdd.vue'),
        //     meta: { has_perm: perm.c_a_member, },
        // },
        {
            path: '/members/view/:memberid',
            name: 'view-member',
            component: () => import('@/views/members/memberView.vue'),
            meta: { has_perm: perm.c_v_member, },
        },

        {
            path: '/members/edit/:memberid',
            name: 'edit-member',
            component: () => import('@/views/members/memberEdit.vue'),
            meta: { has_perm: perm.c_e_member, },
        },

        {
            path: '/members/viewpayment/:memberid',
            name: 'view-member-payment',
            component: () => import('@/views/members/memberPayments.vue'),
            meta: { has_perm: perm.c_v_member, },
        },

        {
            path: '/mahaladminuser/add',
            name: 'new-mahals-adm',
            component: () => import('@/views/mahaladmin/adminusers/addAdmin.vue'),
            meta: { has_perm: perm.c_a_admin, },
        },
        {
            path: '/mahaladminuser/list',
            name: 'manage-mah-admins',
            component: () => import('@/views/mahaladmin/adminusers/listAdminUsers.vue'),
            meta: { has_perm: perm.c_v_admin, },
        },
        {
            path: '/mahaladminrole/add',
            name: 'new-mahals-adm-role',
            component: () => import('@/views/mahaladmin/adminusers/addRoles.vue'),
            meta: { has_perm: perm.c_a_role, },
        },
        {
            path: '/mahaladminrole/edit/:roleid',
            name: 'edit-mahal-role',
            component: () => import('@/views/mahaladmin/adminusers/editRoles.vue'),
            meta: { has_perm: perm.c_e_role, },
        },
        {
            path: '/mahaladminrole/list',
            name: 'list-mahals-adm-role',
            component: () => import('@/views/mahaladmin/adminusers/listRoles.vue'),
            meta: { has_perm: perm.c_v_role, },
        },
        {
            path: '/mahal/newbirth',
            name: 'birth-new',
            component: () => import('@/views/mahaladmin/birth/birthAdd.vue'),
            meta: { has_perm: perm.c_a_birth, },
        },
        {
            path: '/mahal/births',
            name: 'birth-list',
            component: () => import('@/views/mahaladmin/birth/birthList.vue'),
            meta: { has_perm: perm.c_v_birth, },
        },
        {
            path: '/mahal/birthedit/:member_id',
            name: 'edit-birth',
            component: () => import('@/views/mahaladmin/birth/birthEdit.vue'),
            meta: { has_perm: perm.c_e_birth, },
        },

        {
            path: '/mahal/registercomplaint',
            name: 'complaint-new',
            component: () => import('@/views/mahaladmin/registration/complaintAdd.vue'),
            meta: { has_perm: perm.c_v_complaint, },
        },
        {
            path: '/mahal/registerdars',
            name: 'dars-register',
            component: () => import('@/views/mahaladmin/registration/darsRegister.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahal/registerdeath',
            name: 'death-register',
            component: () => import('@/views/mahaladmin/death/deathRegister.vue'),
            meta: { has_perm: perm.c_a_death, },
        },
        {
            path: '/mahal/deaths',
            name: 'death-list',
            component: () => import('@/views/mahaladmin/death/deathList.vue'),
            meta: { has_perm: perm.c_v_death, },
        },
        {
            path: '/mahal/deathedit/:death_id',
            name: 'edit-death',
            component: () => import('@/views/mahaladmin/death/deathEdit.vue'),
            meta: { has_perm: perm.c_e_death, },
        },
        {
            path: '/mahal/registerissue',
            name: 'issue-register',
            component: () => import('@/views/mahaladmin/registration/issueRegister.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahal/registerletterin',
            name: 'letterin-register',
            component: () => import('@/views/mahaladmin/registration/letterinRegister.vue'),
            meta: { has_perm: perm.c_v_letterin, },
        },
        {
            path: '/mahal/registerwelfare',
            name: 'welfare-register',
            component: () => import('@/views/mahaladmin/registration/welfareRegister.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahal/registerfamily',
            name: 'family-register',
            component: () => import('@/views/mahaladmin/family/familyRegister.vue'),
            meta: { has_perm: perm.c_a_family, },
        },

        {
            path: '/mahal/importexcel',
            name: 'import-excel',
            component: () => import('@/views/mahaladmin/family/familyExcelFormat.vue'),
            meta: { has_perm: perm.c_m_fileupload, },
        },

        {
            path: '/mahal/listfamily',
            name: 'family-list',
            component: () => import('@/views/mahaladmin/family/familyList.vue'),
            meta: { has_perm: perm.c_v_family, },
        },
        {
            path: '/mahal/family/view/:familyid',
            name: 'family-view',
            component: () => import('@/views/mahaladmin/family/familyView.vue'),
            meta: { has_perm: perm.c_v_family, },
        },
        {
            path: '/mahal/family/edit/:familyid',
            name: 'edit-family',
            component: () => import('@/views/mahaladmin/family/familyEdit.vue'),
            meta: { has_perm: perm.c_e_family, },
        },
        {
            path: '/mahal/family/guardian/:memberid',
            name: 'mem_to_guardian-family',
            component: () => import('@/views/mahaladmin/family/membertoGuardian.vue'),
            meta: { has_perm: perm.c_a_family, },
        },
        {
            path: '/mahal/family/payments/:familyid',
            name: 'view-masavari-payment',
            component: () => import('@/views/mahaladmin/family/familyPayments.vue'),
            meta: { has_perm: perm.c_v_family, },
        },
        {
            path: '/mahal/member/payments/:member_id',
            name: 'view-masavari-member-payment',
            component: () => import('@/views/members/memberMasavariPayments.vue'),
            meta: { has_perm: perm.c_v_family, },
        },
        {
            path: '/mahal/divorceadd',
            name: 'divorce-add',
            component: () => import('@/views/mahaladmin/divorce/divorceAdd.vue'),
            meta: { has_perm: perm.c_a_divorce, },
        },
        {
            path: '/mahal/divorces',
            name: 'divorce-list',
            component: () => import('@/views/mahaladmin/divorce/divorceList.vue'),
            meta: { has_perm: perm.c_v_divorce, },
        },
        {
            path: '/mahal/marriageapplication',
            name: 'marriage-app',
            component: () => import('@/views/mahaladmin/marriage/marriageAppRegister.vue'),
            meta: { has_perm: perm.c_a_marriage, },
        },
        {
            path: '/mahal/marriageapplications',
            name: 'marriage-app-list',
            component: () => import('@/views/mahaladmin/marriage/marriageApplicationList.vue'),
            meta: { has_perm: perm.c_v_marriage, },
        },
        {
            path: '/mahal/marriageapp/view/:marr_appid',
            name: 'view-marriage-application',
            component: () => import('@/views/mahaladmin/marriage/marriageApplicationView.vue'),
            meta: { has_perm: perm.c_v_marriage, },
        },
        {
            path: '/mahal/marriageapplications/edit/:marrappid',
            name: 'edit-marr-appl',
            component: () => import('@/views/mahaladmin/marriage/marriageApplicationEdit.vue'),
            meta: { has_perm: perm.c_e_marriage, },
        },
        {
            path: '/mahal/marriageregistrations',
            name: 'marriage-reg-list',
            component: () => import('@/views/mahaladmin/marriage/marriageRegistrationList.vue'),
            meta: { has_perm: perm.c_v_marriage, },
        },
        {
            path: '/mahal/madrassaregistration',
            name: 'madrassa-reg',
            component: () => import('@/views/mahaladmin/registration/madrassaRegister.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahal/information',
            name: 'mahal-info',
            component: () => import('@/views/mahaladmin/administration/mahalInformation.vue'),
            meta: { has_perm: perm.c_v_mahal_info, },
        },
        {
            path: '/mahal/mahalcommittees',
            name: 'mahal-committees',
            component: () => import('@/views/mahaladmin/administration/listMahalCommittees.vue'),
            meta: { has_perm: perm.c_m_mahal_committee, },
        },
        {
            path: '/mahal/mahalcommittee/new',
            name: 'commmittee-add',
            component: () => import('@/views/mahaladmin/administration/addMahalCommittee.vue'),
            meta: { has_perm: perm.c_m_mahal_committee, },
        },
        {
            path: '/mahal/mahalcommittee/edit/:mahalcommitteeid',
            name: 'mahalcommitte-edit',
            component: () => import('@/views/mahaladmin/administration/mahalcommitteeEdit.vue'),
            meta: { has_perm: perm.c_m_mahal_committee, },
        },
        
        {
            path: '/mahal/organisationadmin',
            name: 'organisation-adm-add',
            component: () => import('@/views/mahaladmin/administration/organizationAdd.vue'),
            meta: { has_perm: perm.c_a_organisation, },
        },

        {
            path: '/mahal/organisations',
            name: 'organisation-adm',
            component: () => import('@/views/mahaladmin/administration/listOrganizations.vue'),
            meta: { has_perm: perm.c_v_organisation, },
        },
        {
            path: '/mahal/organisation/view/:organizationid',
            name: 'organisation-view',
            component: () => import('@/views/mahaladmin/administration/viewOrganization.vue'),
            meta: { has_perm: perm.c_v_organisation, },
        },
        {
            path: '/mahal/organisation/edit/:organizationid',
            name: 'organisation-edit',
            component: () => import('@/views/mahaladmin/administration/organizationEdit.vue'),
            meta: { has_perm: perm.c_e_organisation, },
        },
        {
            path: '/mahal/requests',
            name: 'manage-requests',
            component: () => import('@/views/mahaladmin/requests/manageRequests.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahal/accounts/create',
            name: 'accounts-create',
            component: () => import('@/views/mahaladmin/accounts/createAccount.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },
        {
            path: '/mahal/accounts/list',
            name: 'accounts-list',
            component: () => import('@/views/mahaladmin/accounts/listAccounts.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/mahal/accounts/view/:accountid',
            name: 'account-view',
            component: () => import('@/views/mahaladmin/accounts/viewAccount.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },
        {
            path: '/mahal/accounts/edit/:accountid',
            name: 'account-edit',
            component: () => import('@/views/mahaladmin/accounts/accountEdit.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },
        {
            path: '/mahal/accounts/donation/create',
            name: 'create-donation',
            component: () => import('@/views/mahaladmin/accounts/createDonation.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },
        {
            path: '/mahal/accounts/donation/edit/:donation_id',
            name: 'edit-donation',
            component: () => import('@/views/mahaladmin/accounts/editDonation.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/mahal/accounts/donation/list',
            name: 'list-donations',
            component: () => import('@/views/mahaladmin/accounts/listDonations.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/mahal/accounts/transaction/create',
            name: 'create-transaction',
            component: () => import('@/views/mahaladmin/accounts/createTransaction.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        // {
        //     path: '/mahal/accounts/transaction/create',
        //     name: 'create-transaction-revised',
        //     component: () => import('@/views/mahaladmin/accounts/revisedCreateTransaction.vue'),
        //     meta: { has_perm: perm.c_m_accounts, },
        // },

        {
            path: '/mahal/accounts/transactions',
            name: 'list-transactions',
            component: () => import('@/views/mahaladmin/accounts/listTransactions.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },
        {
            path: '/mahal/accounts/transaction/view/:transac_id',
            name: 'view-transaction',
            component: () => import('@/views/mahaladmin/accounts/viewTransaction.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/mahal/accounts/ledgertitle/create',
            name: 'add-ledger-title',
            component: () => import('@/views/mahaladmin/accounts/newLedgerTitle.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/mahal/accounts/income-expense/report',
            name: 'acc-income-exp',
            component: () => import('@/views/mahaladmin/accounts/incexpReport.vue'),
            meta: { has_perm: perm.c_v_accounts_report, },
        },


        {
            path: '/mahal/accounts/bank/report',
            name: 'bank-account',
            component: () => import('@/views/mahaladmin/accounts/bankReport.vue'),
            meta: { has_perm: perm.c_v_bank_report, },
            // meta: { has_perm: true, },
        },


        {
            path: '/mahal/staffs',
            name: 'staff-info',
            component: () => import('@/views/mahaladmin/administration/listStaffs.vue'),
            meta: { has_perm: perm.c_v_staff, },
        },

        {
            path: '/mahal/staffs/create',
            name: 'new-mahal-staff',
            component: () => import('@/views/mahaladmin/administration/addStaff.vue'),
            meta: { has_perm: perm.c_a_staff, },
        },
        {
            path: '/mahal/staffs/view/:staffid',
            name: 'mahal-staff-view',
            component: () => import('@/views/mahaladmin/administration/viewStaff.vue'),
            meta: { has_perm: perm.c_v_staff, },
        },
        {
            path: '/mahal/staffs/edit/:staffid',
            name: 'mahal-staff-edit',
            component: () => import('@/views/mahaladmin/administration/editStaff.vue'),
            meta: { has_perm: perm.c_e_staff, },
        },


        {
            path: '/mahal/minutes',
            name: 'minutes-info',
            component: () => import('@/views/mahaladmin/administration/listMinutes.vue'),
            meta: { has_perm: perm.c_v_minutes, },
        },
        {
            path: '/mahal/newmeetings',
            name: 'new-meeting',
            component: () => import('@/views/mahaladmin/administration/addNewMeeting.vue'),
            meta: { has_perm: perm.c_a_minutes, },
        },

        {
            path: '/mahal/viewminutes/:minutsid',
            name: 'mahal-minuts-view',
            component: () => import('@/views/mahaladmin/administration/viewMinuts.vue'),
            meta: { has_perm: perm.c_v_minutes, },
        },
        {
            path: '/mahal/minutes/:minutsid',
            name: 'mahal-minuts-edit',
            component: () => import('@/views/mahaladmin/administration/editMinuts.vue'),
            meta: { has_perm: perm.c_e_minutes, },
        },











        {
            path: '/mahal/subcommitee',
            name: 'subcomm-adm',
            component: () => import('@/views/mahaladmin/administration/listSubcommitties.vue'),
            meta: { has_perm: perm.c_v_sub_comm, },
        },
        {
            path: '/mahal/subcommiteeadd',
            name: 'subcomm-add',
            component: () => import('@/views/mahaladmin/administration/subcommitteeAdd.vue'),
            meta: { has_perm: perm.c_a_sub_comm, },
        },
        {
            path: '/mahal/subcommitte/edit/:subcommitteid',
            name: 'subcommitte-edit',
            component: () => import('@/views/mahaladmin/administration/subcommitteEdit.vue'),
            meta: { has_perm: perm.c_e_sub_comm, },
        },
        {
            path: '/mahal/qabar',
            name: 'qabar-location',
            component: () => import('@/views/mahaladmin/administration/listQabar.vue'),
            meta: { has_perm: perm.c_v_qabar, },
        },
        {
            path: '/mahal/addqabar',
            name: 'add-qabar',
            component: () => import('@/views/mahaladmin/administration/addQabar.vue'),
            meta: { has_perm: perm.c_a_qabar, },
        },
        {
            path: '/mahal/qabar/edit/:qabarid',
            name: 'qabar-edit',
            component: () => import('@/views/mahaladmin/administration/editQabar.vue'),
            meta: { has_perm: perm.c_e_qabar,},
        },




        {
            path: '/mahal/charity',
            name: 'charity_details',
            component: () => import('@/views/mahaladmin/administration/listCharity.vue'),
            meta: { has_perm: perm.c_v_charity, },
        },
        {
            path: '/mahal/addcharity',
            name: 'add-charity',
            component: () => import('@/views/mahaladmin/administration/addCharity.vue'),
            meta: { has_perm: perm.c_a_charity, },
        },

        // {
        //     path: '/mahal/notcharity',
        //     name: 'CharityNotFound',
        //     component: () => import('@/views/mahaladmin/administration/CharityNotFound.vue'),
        //     meta: { has_perm: perm.c_e_qabar, },
        // },
        
        {
            path: '/mahal/charity/edit/:charity_id',
            name: 'charity-edit',
            component: () => import('@/views/mahaladmin/administration/editCharity.vue'),
            meta: { has_perm: perm.c_e_charity, },
        },
        {
            path: '/mahal/assets',
            name: 'list-assets',
            component: () => import('@/views/mahaladmin/assets/listAsset.vue'),
            meta: { has_perm: perm.c_v_assets_st, },
        },
        {
            path: '/mahal/add/asset',
            name: 'add-asset',
            component: () => import('@/views/mahaladmin/assets/addAsset.vue'),
            meta: { has_perm: perm.c_a_assets_st, },
        },
        {
            path: '/mahal/asset/edit/:assetid',
            name: 'asset-edit',
            component: () => import('@/views/mahaladmin/assets/editAsset.vue'),
            meta: { has_perm: perm.c_e_assets_st,},
        },
        {
            path: '/mahal/stocks',
            name: 'list-stocks',
            component: () => import('@/views/mahaladmin/assets/listStock.vue'),
            meta: { has_perm: perm.c_v_assets_st, },
        },
        {
            path: '/mahal/addstock',
            name: 'add-stock',
            component: () => import('@/views/mahaladmin/assets/addStock.vue'),
            meta: { has_perm: perm.c_a_assets_st, },
        },
        {
            path: '/mahal/stock/edit/:stockid',
            name: 'stock-edit',
            component: () => import('@/views/mahaladmin/assets/editStock.vue'),
            meta: { has_perm: perm.c_e_assets_st,},
        },
        {
            path: '/mahal/charityapproval',
            name: 'charity_approve',
            component: () => import('@/views/mahaladmin/administration/listCharityApproval.vue'),
            meta: { has_perm: perm.c_appr_charity, },
        },
        {
            path: '/mahal/charity/approve/:charity_id',
            name: 'charity-approve',
            component: () => import('@/views/mahaladmin/administration/ApproveCharity.vue'),
            meta: { has_perm: perm.c_appr_charity,},
        },

        {
            path: '/mahal/familysubscription',
            name: 'family-subscription',
            component: () => import('@/views/mahaladmin/accounts/listfamilySubscription.vue'),
            meta: { has_perm: perm.c_m_accounts , },
        },
        {
            path: '/mahal/notifications',
            name: 'notification',
            component: () => import('@/views/mahaladmin/notification/allMahalNotifications.vue'),
            meta: { has_perm: true, },
        },

        {
            path: '/mahal/change/familysubscription',
            name: 'family-subscription-change',
            component: () => import('@/views/mahaladmin/accounts/changeFamilysubscription.vue'),
            meta: { has_perm: perm.c_m_masavari , },
        },
        {
            path: '/mahal/birthreport',
            name: 'birth-report',
            component: () => import('@/views/mahaladmin/report/birthReport.vue'),
            meta: { has_perm: perm.c_v_birth_report, },
        },
        {
            path: '/mahal/deathreport',
            name: 'death-report',
            component: () => import('@/views/mahaladmin/death/deathReport.vue'),
            meta: { has_perm: perm.c_v_death_report, },
        },
        {
            path: '/mahal/divorcereport',
            name: 'divorce-report',
            component: () => import('@/views/mahaladmin/divorce/divorceReport.vue'),
            meta: { has_perm: perm.c_v_divorce_report, },
        },
        {
            path: '/mahal/familyreport',
            name: 'family-report',
            component: () => import('@/views/mahaladmin/family/familyReport.vue'),
            meta: { has_perm: perm.c_v_family_report, },
        },

        {
            path: '/mahal/marriageapplicationreport',
            name: 'marry-app-report',
            component: () => import('@/views/mahaladmin/marriage/marriageApplicationReport.vue'),
            meta: { has_perm: perm.c_v_marr_app_report, },
        },
        {
            path: '/mahal/marriagereport',
            name: 'marry-report',
            component: () => import('@/views/mahaladmin/marriage/marriageReport.vue'),
            meta: { has_perm: perm.c_v_marr_reg_report, },
        },

        {
            path: '/mahal/memberreport',
            name: 'member-report',
            component: () => import('@/views/mahaladmin/report/memberReport.vue'),
            meta: { has_perm: perm.c_v_member_report, },
        },
        {
            path: '/mahal/staff/report',
            name: 'staff-report',
            component: () => import('@/views/mahaladmin/report/staffReport.vue'),
            meta: { has_perm: perm.c_v_staff_report, },
            // meta: { has_perm: true, },
        },

        {
            path: '/masterdata/occupation',
            name: 'md-occupation',
            component: () => import('@/views/masterdata/occupationAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/area',
            name: 'md-area',
            component: () => import('@/views/masterdata/areaAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/block',
            name: 'md-block',
            component: () => import('@/views/masterdata/blockAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/bloodgroup',
            name: 'md-bloodgroup',
            component: () => import('@/views/masterdata/bloodgroupAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/familycategory',
            name: 'md-familycategory',
            component: () => import('@/views/masterdata/familycategoryAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/studentclass', //not used
            name: 'md-studentclass',
            component: () => import('@/views/masterdata/classstudentAdd.vue'),
            meta: { has_perm: false, },
        },
        {
            path: '/masterdata/habit',
            name: 'md-habit',
            component: () => import('@/views/masterdata/habitAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/healthstatus',
            name: 'md-healthstatus',
            component: () => import('@/views/masterdata/healthstatusAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/housefacility',
            name: 'md-housefacility',
            component: () => import('@/views/masterdata/housefacilityAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/housetype',
            name: 'md-housetype',
            component: () => import('@/views/masterdata/housetypeAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/income',
            name: 'md-income',
            component: () => import('@/views/masterdata/incomeAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/maritalstatus',
            name: 'md-maritalstatus',
            component: () => import('@/views/masterdata/maritalstatusAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/marriageapprovestatus',
            name: 'md-marriageapprovestatus',
            component: () => import('@/views/masterdata/marriageapprovestatusAdd.vue'),
            meta: { has_perm: false, },
        },
        {
            path: '/masterdata/memberrole',
            name: 'md-memberrole',
            component: () => import('@/views/masterdata/memberroleAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/newspaper',
            name: 'md-newspaper',
            component: () => import('@/views/masterdata/newspaperAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/panchayat',
            name: 'md-panchayat',
            component: () => import('@/views/masterdata/panchayatAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/programstatus',
            name: 'md-programstatus',
            component: () => import('@/views/masterdata/programstatusAdd.vue'),
            meta: { has_perm: false, },
        },
        {
            path: '/masterdata/programtype',
            name: 'md-programtype',
            component: () => import('@/views/masterdata/programtypeAdd.vue'),
            meta: { has_perm: false, },
        },
        {
            path: '/masterdata/relation',
            name: 'md-relation',
            component: () => import('@/views/masterdata/relationAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/hobby',
            name: 'md-hobby',
            component: () => import('@/views/masterdata/hobbyAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/subscription',
            name: 'md-subscription',
            component: () => import('@/views/masterdata/subscriptiontypeAdd.vue'),
            meta: { has_perm: false, },
        },
        {
            path: '/masterdata/vehicle',
            name: 'md-vehicle',
            component: () => import('@/views/masterdata/vehicleAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/ward',
            name: 'md-ward',
            component: () => import('@/views/masterdata/wardAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/zone',
            name: 'md-zone',
            component: () => import('@/views/masterdata/zoneAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/rqualification',
            name: 'md-rquali',
            component: () => import('@/views/masterdata/rqualificationAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/squalification',
            name: 'md-squali',
            component: () => import('@/views/masterdata/squalificationAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/ledgertitle',
            name: 'md-ledger',
            component: () => import('@/views/masterdata/ledgertitleAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/masterdata/qualification',
            name: 'md-quali',
            component: () => import('@/views/masterdata/qualificationAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },

        {
            path: '/mahal/watermark',
            name: 'watermarkreceipt',
            component: () => import('@/views/masterdata/listwaterMark.vue'),
            meta: { has_perm: perm.c_m_md, },
        },

        {
            path: '/mahal/watermark',
            name: 'add-watermark',
            component: () => import('@/views/masterdata/addwaterMark.vue'),
            meta: { has_perm: perm.c_m_md,},
        },
        {
            path: '/masterdata/plans',
            name: 'md_plan',
            component: () => import('@/views/masterdata/planAdd.vue'),
            meta: { has_perm: perm.c_m_md, },
        },

        {
            path: '/mahal/watermark/edit/:watrmrk_id',
            name: 'watermark-edit',
            component: () => import('@/views/masterdata/editwaterMark.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        
        {
            path: '/masterdata/dashboard',
            name: 'md-menupage',
            component: () => import('@/views/menupages/masterData.vue'),
            meta: { has_perm: perm.c_m_md, },
        },
        {
            path: '/mahalregistrations/dashboard',
            name: 'mahal-registrations',
            component: () => import('@/views/menupages/registerMenu.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/mahalreports/dashboard',
            name: 'mahal-reports-menu',
            component: () => import('@/views/menupages/reportsMenu.vue'),
            meta: { has_perm: true, },
        },

        {
            path: '/mahalaccounts/dashboard',
            name: 'mahal-accounts-menu',
            component: () => import('@/views/menupages/accountsMenu.vue'),
            meta: { has_perm: perm.c_m_accounts, },
        },

        {
            path: '/staffs',
            name: 'staffs',
            component: () => import('@/views/hyzadmin/staff/staffList.vue'),
            meta: { has_perm: perm.c_v_h_staff, },
        },
        {
            path: '/staffs/add',
            name: 'new-staffs',
            component: () => import('@/views/hyzadmin/staff/staffAdd.vue'),
            meta: { has_perm: perm.c_a_h_staff, },
        },
        {
            path: '/staffs/edit/:staffid',
            name: 'edit-staff',
            component: () => import('@/views/hyzadmin/staff/staffEdit.vue'),
            meta: { has_perm: perm.c_e_h_staff, },
        },
        {
            path: '/staffs/view/:staffid',
            name: 'view-staff',
            component: () => import('@/views/hyzadmin/staff/viewStaff.vue'),
            meta: { has_perm: perm.c_v_h_staff, },
        },
        {
            path: '/mahals',
            name: 'mahals',
            component: () => import('@/views/hyzadmin/mahal/mahalsList.vue'),
            meta: { has_perm: perm.c_v_mahal, },
        },
        {
            path: '/mahals/add',
            name: 'new-mahals',
            component: () => import('@/views/hyzadmin/mahal/mahalAdd.vue'),
            meta: { has_perm: perm.c_a_mahal, },
        },
        {
            path: '/mahal/view/:mahalid',
            name: 'view-mahal',
            component: () => import('@/views/hyzadmin/mahal/mahalView.vue'),
            meta: { has_perm: perm.c_v_mahal, },
        },
        {
            path: '/mahals/edit/:mahalid',
            name: 'edit-mahal',
            component: () => import('@/views/hyzadmin/mahal/mahalEdit.vue'),
            meta: { has_perm: perm.c_e_mahal, },
        },

        {
            path: '/hyzroles',
            name: 'hyzroles',
            component: () => import('@/views/hyzadmin/roles/roleList.vue'),
            meta: { has_perm: perm.c_v_h_role, },
        },
        {
            path: '/hyzroles/add',
            name: 'new-hyzrole',
            component: () => import('@/views/hyzadmin/roles/roleAdd.vue'),
            meta: { has_perm: perm.c_a_h_role, },
        },
        {
            path: '/hyzroles/edit/:roleid',
            name: 'edit-hyz-role',
            component: () => import('@/views/hyzadmin/roles/roleEdit.vue'),
            meta: { has_perm: perm.c_e_h_role, },
        },
        {
            path: '/mahaladmins',
            name: 'mahal-admins',
            component: () => import('@/views/hyzadmin/mahal/mahalAdminList.vue'),
            meta: { has_perm: perm.c_v_mahal_adm, },
        },
        {
            path: '/jsonlanguage',
            name: 'jsonlanguage',
            component: () => import('@/views/jsnRetun.vue'),
            meta: { has_perm: true, },
           
        },
        {
            path: '/payments',
            name: 'payment-details',
            component: () => import('@/views/hyzadmin/payments/allpaymentDetails.vue'),
            meta: { has_perm: true, },
           
        },

       
        ]
    },

    {
        path: '/legal',
        name: 'legal',
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
        children: [{
            path: 'privacy-policy',
            name: 'privacy-policy',
            component: () => import('@/views/legal/privacyPolicy.vue')
        }]
    },
   
    {
        path: '/registration',
        name: 'registration',
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
      
        beforeEnter: (to, from, next) => {

            const loggedIn = localStorage.getItem('user');
            if (!loggedIn) {
                // If the user is not authenticated, allow access to the registration page
              next(); 
            } 
            else{
                return next('/not-found');
            }
           
          },
          component: () => import('@/views/site_registration/siteRegistration.vue')
       
    },

    {
        path: '/payment',
        name: 'payment',
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
      
        beforeEnter: (to, from, next) => {

            const loggedIn = localStorage.getItem('user');
            if (!loggedIn) {
                // If the user is not authenticated, allow access to the registration page
              next(); 
            } 
            else{
                return next('/not-found');
            }
           
          },
          component: () => import('@/views/site_registration/proceedPayment.vue')
       
    },
    {
        path: '/payment-proceedings/:slug',
        name: 'payment-proceed',
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
      
        beforeEnter: (to, from, next) => {

            const loggedIn = localStorage.getItem('user');
            if (!loggedIn) {
                // If the user is not authenticated, allow access to the registration page
              next(); 
            } 
            else{
                return next('/not-found');
            }
           
          },
          component: () => import('@/views/site_registration/paymentDetails.vue')
       
    },



    {
        path: '/auth',
        name: 'Auth',
        component: () =>
            import('@/layouts/AuthenticationLayout.vue'),
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
        children: [{
            path: '/login/:type?',
            name: 'login',
            component: () => import('@/views/auth/LoginPage.vue')
        },

        {
            path: '/forgotpassword',
            name: 'forgotpassword',
            component: () => import('@/views/auth/ForgotPassword.vue')
        },

        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, },
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('user');
    const hasPermission = to.matched.some(record => record.meta.has_perm)

    //console.log(authRequired);
    if (authRequired && !loggedIn) {
        const path = ''
        next('/login' + path);
    }else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }

})


export default router